#SummaryColumn
    max-width: 300px
    width: 100%
    border-right: 1px solid #A6B9C811
    margin-top: -1rem
    margin-bottom: -1rem
    margin-left: -1rem
    margin-right: 50px
    height: calc( 100vh - 88px - 40px - 1rem )
    flex-shrink: 0
    flex-grow: 0
    color: #A6B9C8

    &.expanded
        height: calc( 100vh - 40px - 1rem )

    .SynthesisGraphWrapper
        width: 300px
        border-bottom : 1px solid #A6B9C811

        svg
            margin: 2rem 1rem

    .content
        overflow-y: scroll
        overflow-x: hidden
        text-align: center
        padding: 0.5rem 1rem
        height: calc( 100% - 300px )
        padding-bottom: 4rem