.Indicators
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

	h2
		font-size: 1.1rem
		padding: 0 1rem
		color: #2EA0DF
		text-align: center

	h3
		font-size: 0.8rem
		color: #2EA0DF
		margin-bottom: 1.5rem
		text-align: center

	.name
		width: 50%
		.description
			display: block
			font-size: 11px
			max-height: 20px
			white-space: pre
			overflow: hidden
			text-overflow: ellipsis