.IconPickerContainer
	svg
		min-width: 30px
		min-height: 30px
		max-width: 30px
		max-height: 30px
		margin: 1px

	.title
		width: 100%
		font-size: 14px
		margin: 0px 0px 5px
		text-align: left
		padding: 0px 5px 0px 3px
		color: #888888
