.Projects
	padding: 1rem
	display: flex
	flex-direction: row
	flex-wrap: wrap

	.ProjectItem
		margin-bottom: 0.5rem
		margin-right: 0.5rem
		cursor: pointer

	.footer
		width: 100%
		display: block
		.ActionButton
			margin: auto
			margin-top: 2rem

	.subscribeNow
		text-align: center
		cursor: pointer
		width: 100%
		color: orange
		margin: auto
		font-size: 0.9rem
		margin-top: 2rem

		svg
			font-size: 1.1rem
			margin: 0 0.5rem