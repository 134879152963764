.EditableListItem
	color: #A6B9C8
	width: 100%
	min-height: 48px
	display: flex
	align-items: center
	border-top: 1px solid #DDDDDD

	&:first-child
		border-top: none

	svg:not(.fa-check)
		font-size: 20px
		margin-right: 1rem
		
	.Weight
		margin-left: 2rem

	.TrendSelector + .Weight
		margin-left: 2rem

	&.nomargin
		
		.Weight

			margin-left: 1rem