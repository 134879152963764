.MiniSliderWrapper
	padding: 0
	padding-top: 5px
	position: relative

	.ant-slider
		height: 5px
		margin: 0 !important
		background: linear-gradient(90deg, #E70549 0%, #00B0A9 100%)
		border-radius: 1px

		.ant-slider-rail, .ant-slider-track
			display: none

	.ant-slider-handle
		top: 0px
		border-radius: 0px
		height: 19px
		background: white
		width: 10px
		box-sizing: border-box
		cursor: pointer
		border: 1px solid
		z-index: 2

	.ant-slider-disabled
		.ant-slider-handle
			width: 4px
			height: 30px

	.marker
		position: absolute
		width: 5px
		top: 0px
		height: 19px
		opacity: 0.25
		background: white
		z-index: 1
		margin-left: -2px
		
	&.highContrast
		.marker
			background: black
			width: 4px
			margin-left: -2px