.FishboneBranchPopover
    min-width: 180px
    flex-shrink: 0

    .title
        width: 100px
        flex-shrink: 0

    .description
        margin-bottom: 0.5em

    .MiniSliderWrapper
        width: 150px
        flex-shrink: 0