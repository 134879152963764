.ScenarioResult
	color: white

	h2
		font-size: 0.8rem
		color: #2EA0DF
		text-align: center
		display: flex
		justify-content: center
		align-items: center
		height: 100%

	h3
		font-size: 1rem
		color: #2EA0DF
		margin-bottom: 1.5rem
		text-align: center

	.Navigation
		width: 100%
	.action
		width: 2.5rem
		height: 2.5rem
		border-radius: 1.25rem
		background: #FFFFFF22
		flex-shrink: 0
		display: flex
		justify-content: center
		align-items: center

	.ElementsRank
		margin-top: 1rem
		margin-bottom: 1rem
		color: white

		.title
			padding: 3px 5px
			background: #A6B9C8
			color: black
			flex-wrap: nowrap
			display: flex
			align-items: center

		.RankCriteriaColumn
			width: 33% 

			.RankCriteriaWrapper
				border-bottom: 1px solid #FFFFFF30

			.RankCriteria
				padding: 3px 5px
		>div+div
			margin-left: 2px

	.ant-slider-handle
		transition-duration: 0.5s
		transition-property: left

	.rp45, .rm45, .r0
		transform-origin: center
		width: 22px
		height: 22px
		display: inline-block

	.rp45
		transform: translate(0px, 2px) rotate(45deg)
		color: #E70549
    
	.rm45
		transform: translate(0px, -2px) rotate(-45deg)
		color: #00B0A9

	.r0
		transform: translate(2px, 0px)
		color: grey