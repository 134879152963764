.FishbonePoint
    animation-name: appear-FishbonePoint
    animation-duration: 1s
    animation-timing-function: linear
    animation-iteration-count: 1
    transition-property: transform
    transition-duration: 1s

    circle
        transition: fill 1s

    @keyframes appear-FishbonePoint
        0%
            opacity: 0
        100%
            opacity: 1

    .completion
        transition-property: opacity
        transition-duration: 0.2s

        &.hidden
            opacity: 0

        @keyframes transform-completion
            0%
                opacity: 0