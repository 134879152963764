.NavigationButton
	border-radius: 3px
	background: none
	display: flex
	justify-content: center
	align-items: center
	font-size: 16px
	padding: 5px 10px
	text-transform: uppercase
	color: #24CCB8
	cursor: pointer