.Profile
	padding: 1rem
	color: white

	.infos
		padding: 1rem
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		margin-bottom: 1rem
		
		img
			border-radius: 40px
			width: 80px
			height: 80px
			margin-right: 1rem
			background: #DDDDDD
			margin-left: -3rem

		h2
			font-size: 26px
			color: white !important

		h2, p
			margin: 0
			line-height: 28px

	.title
		width: 100%
		text-align: center
		font-size: 1.8rem
		color: #A6B9C8
		padding: 0rem 0 0.1rem 0

	.subtitle
		width: 100%
		text-align: center
		font-size: .9rem
		color: #A6B9C8
		padding: 0rem 0 1.5rem 0
		
	.plans
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		border: 1px solid #A6B9C844
		max-width: 750px
		margin: 1rem auto
		border-radius: 6px
		padding: 10px
		

		.plan
			width: calc(100%/3 - 5px)
			border: 1px solid #FFFFFF88
			min-height: 250px
			display: flex
			justify-content: flex-start
			align-items: center
			flex-direction: column
			background: white
			padding: 1rem 0
			color: #999999

			h3
				color: #666666
				font-size: 2rem
				padding: 0
				margin: 0 0 0.5rem

			p
				height: 2.5rem
				padding: 0 2rem
				color: A6B9C8

			.paymentButton
				background: #00b03e
				padding: 15px 40px
				margin: 0px 0px 1rem
				border-radius: 4px
				color: white
				cursor: pointer
				width: 170px
				text-align: center

				.Loader
					min-height: 22px !important
					height: 22px !important

			.price
				font-size: 1.1rem
			small
				text-align: center
				display: block
				margin-left: 5px
				font-size: 0.6rem