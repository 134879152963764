.RadialVulnerabilityGauge
    font-family: "Public sans", Helvetica Neue, sans-serif
    overflow: visible !important

    .npsItem
        display: flex
        &.negative
            fill: #E70549

        &.neutral
            fill: #FFFFFF99

        &.positive
            fill: #00B0A9

       
    .vulnerabilityScore
        transition-property: fill
        transition-duration: 1s

    .positiveItem
       transform: scale(0.5 0.5)

    .arc
        transition-property: stroke-dasharray stroke
        transition-duration: 1s